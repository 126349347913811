





























import {Component, Prop, VModel, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import ArchiveComponent from '@/components/shared/Archive.component.vue';
import Location from '@/models/Location';


const CustomerStore = namespace('customer');

const LOCATION_ATTRIBUTE = 'location';

@Component({
  components: {ArchiveComponent},
})
export default class LocationCleaningDataComponent extends Vue {
  @CustomerStore.Getter('location')
  public _location!: Location;

  private localLocation: any;
  private rendered: boolean = false;

  @CustomerStore.Action('editLocationAction')
  private editLocationAction!: (payload: { location: Location, shouldBeStored: boolean }) => Promise<Location>;

  public async mounted() {
    this.localLocation = this[LOCATION_ATTRIBUTE].copy();

    this.rendered = true;
  }

  private get [LOCATION_ATTRIBUTE]() {
    return this._location;
  }

  /**
   * Submit method (user presses submit button)
   */
  private async onSubmit(): Promise<void> {
    if (this._location.freeTextField !== this.localLocation.freeTextField) {
      this._location.freeTextField = this.localLocation.freeTextField;

      try {
        await this.editLocationAction({
          location: this._location,
          shouldBeStored: false,
        });

        this.$notifySuccessSimplified('CUSTOMER_DASHBOARD.NOTIFICATIONS.LOCATION_EDIT.SUCCESS');
        this.$emit('done', this.location);
      } catch (e) {
        if (e.status === 409) {
          this.$notifyErrorSimplified(`CUSTOMER_DASHBOARD.NOTIFICATIONS.LOCATION_EDIT.${e.status}`);
        } else {
          this.$notifyErrorSimplified('CUSTOMER_DASHBOARD.NOTIFICATIONS.LOCATION_EDIT.ERROR');
        }
      }
    }
  }
}
